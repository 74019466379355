/* eslint-disable react-hooks/exhaustive-deps */
import "../../global.css";
import React, { useEffect, useState, useRef, useCallback } from "react";

import {
  ImagesContainer,
  ImageFull,
  Image1,
  Image2,
  Image3,
  Image4,
  Container,
  Aside,
  Body,
  Tag,
  ImagesContainerMobile,
  ImageFullMobile,
  ArrowSlide,
  SpanArrow,
  DownloadImage,
  CountImage,
  DownloadIV,
  ArrowSlideText,
  FeatureContent,
} from "./style";

import { Link, useParams } from "react-router-dom";
import moment from "moment";
import PhotosModal from "../../components/PhotosModal";

import api from "../../api";

// Icons
import { CardChecklist } from "@styled-icons/bootstrap/CardChecklist";
import { PlayStore } from "@styled-icons/boxicons-logos/PlayStore";
import { Apple } from "@styled-icons/boxicons-logos/Apple";
import { Modal } from "antd";
import ImageButton from "./components/ImageButton";

const COMERCIAL_GENERAL_TYPE = 1644602035863502;
const getGeneralTypeName = (value) => value === COMERCIAL_GENERAL_TYPE ? 'Comercial' : 'Residencial';
function PropertyDetail() {
  const [data, setData] = useState();
  const [modalFeatures, setModalFeatures] = useState(false);
  const [autoTag, setTag] = useState("");
  const [modal, setModal] = useState(false);
  const [photoModal, setPhotoModal] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [auxIndex, setAuxIndex] = useState(0);
  const [slider, setSlider] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [useApp, setUseApp] = useState();
  const [lockId, setLockId] = useState(null);
  const userId = localStorage.getItem('id');
  const [currentUserAgent, setCurrentUserAgent] = useState(undefined);

  const screen = {
    pageStartX: 0,
  };

  useEffect(() => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      setIsMobile(true);
    }

    if (data && data.images) {
      let ImagesTrue = [];
      for (let i = 0; i < data.images.length; i++) {
        if (data.images[i].show != "false") {
          ImagesTrue.push(data.images[i]);
        }
      }

      setImages(ImagesTrue);
    }
  }, [data]);

  useEffect(() => {
    if (images.length > 0) {
      const sliderElement = document.querySelector(".slider");

      if (sliderElement != slider) {
        setSlider(sliderElement);
      }

      if (slider) {
        const touchstart = (e) => {
          return (screen.pageStartX = e.changedTouches[0].pageX);
        };

        const touchend = (e) => {
          if (e.changedTouches[0].pageX < screen.pageStartX) {
            if (index != images.length - 1) setAuxIndex(auxIndex + 1);

            return;
          }

          if (e.changedTouches[0].pageX > screen.pageStartX) {
            if (index != 0) setAuxIndex(auxIndex - 1);

            return;
          }
        };

        slider.removeEventListener("touchstart", touchstart);
        slider.removeEventListener("touchend", touchend);

        slider.addEventListener("touchstart", touchstart, { once: true });

        slider.addEventListener("touchend", touchend, { once: true });
      }
    }
  }, [images, slider, auxIndex, refresh]);

  useEffect(() => {
    if (auxIndex != index) {
      if (auxIndex > images.length - 1) {
        setIndex(images.length - 1);
        setAuxIndex(images.length - 1);
      } else {
        if (auxIndex < 0) {
          setAuxIndex(0);
        } else {
          setIndex(auxIndex);
        }
      }
    }
  }, [auxIndex]);

  const tag = useRef();
  const description = useRef();
  const sellingNotes = useRef();

  const params = useParams();

  async function fetchData() {
    const response = await api
      .get(`/property/${params.id}`)
      .catch((err) => console.error(err));

    setUseApp(response.data.useApp);
    setLockId(response.data.lockId);

    handleCompact(params.id);
    handleVisualization(params.id);
    const obj = response.data;

    obj.images.sort((a, b) => {
      if (a["order"] > b["order"]) {
        return 1;
      } else {
        return -1;
      }
    });

    setData(obj);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterTag();
  }, [data]);

  function filterTag() {
    if (data != undefined) {
      if (Date.parse(data.createdAt)) {
        const createdAt = moment(new Date(data.createdAt)).diff(
          moment(Date.now())
        );

        const age = moment.duration(createdAt);

        let datase =
          data.createdAt.split(" ")[0].split("/")[2] +
          "-" +
          data.createdAt.split(" ")[0].split("/")[1] +
          "-" +
          data.createdAt.split(" ")[0].split("/")[0];

        var dataBanco = new Date(datase);

        if (isNaN(dataBanco.getTime())) {
          dataBanco = new Date("2020", "01", "01");
        }
        var date2 = new Date();

        var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (data.sold) {
          tag.current.style.backgroundColor = "#1E1D1D";
          return setTag("VENDIDO");
        }

        if (data.showTag && data.tag) {
          tag.current.style.backgroundColor = "#00AEC8";
          return setTag(data.tag.name);
        }

        if (diffDays <= 2) {
          tag.current.style.backgroundColor = "#1C9C40";
          return setTag("NOVIDADE");
        }

        if (
          data.descountPrice &&
          data.descountPrice != data.sellingPrice &&
          data.descountPrice < data.sellingPrice
        ) {
          tag.current.style.backgroundColor = "#EA1E1E";
          return setTag(
            "DESCONTO DE " +
            Math.abs(
              Math.floor((data.descountPrice / data.sellingPrice) * 100 - 100)
            ).toString() +
            "%"
          );
        }

        if (data.tag) {
          tag.current.style.backgroundColor = "#00AEC8";
          return setTag(data.tag.name);
        } else {
          tag.current.style.opacity = "0";
        }
      } else {
        const createdAt = moment(data.createdAt, "DD/MM/YYYY HH:mm").diff(
          moment(Date.now())
        );

        const age = moment.duration(createdAt);

        let datase =
          data.createdAt.split(" ")[0].split("/")[2] +
          "-" +
          data.createdAt.split(" ")[0].split("/")[1] +
          "-" +
          data.createdAt.split(" ")[0].split("/")[0];

        var dataBanco = new Date(datase);

        if (isNaN(dataBanco.getTime())) {
          dataBanco = new Date("2020", "01", "01");
        }
        var date2 = new Date();

        var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (data.sold) {
          tag.current.style.backgroundColor = "#1E1D1D";
          return setTag("VENDIDO");
        }

        if (data.showTag && data.tag) {
          tag.current.style.backgroundColor = "#00AEC8";
          return setTag(data.tag.name);
        }

        if (diffDays <= 2) {
          tag.current.style.backgroundColor = "#1C9C40";
          return setTag("NOVIDADE");
        }

        if (
          data.descountPrice &&
          data.descountPrice != data.sellingPrice &&
          data.descountPrice < data.sellingPrice
        ) {
          tag.current.style.backgroundColor = "#EA1E1E";
          return setTag(
            "DESCONTO DE " +
            Math.abs(
              Math.floor((data.descountPrice / data.sellingPrice) * 100 - 100)
            ).toString() +
            "%"
          );
        }
        if (data.tag) {
          tag.current.style.backgroundColor = "#00AEC8";
          return setTag(data.tag.name);
        } else {
          tag.current.style.opacity = "0";
        }
      }
    }
  }

  async function handleDownload() {
    try {
      // navigator.share({
      //   files: images.map((i)=> `${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${i.image}`),
      //   title: data.address,
      //   url: `${window.location.origin}/${data._id}`,

      // })
      window.open(
        `${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${images[index].image}`,
        "_blank"
      );

      // var imgCanvas = document.createElement("canvas"),
      // imgContext = imgCanvas.getContext("2d");

      // imgCanvas.width = img.width;
      // imgCanvas.height = img.height;

      // imgContext.drawImage(img, 0, 0, img.width, img.height);

      // var imgAsDataURL = imgCanvas.toDataURL("image/png");

      //     fetch(imgAsDataURL).then(async (result) =>{
      //         var blob = await result.blob();
      //         var anchor = window.document.createElement('a');
      //         anchor.href = window.URL.createObjectURL(blob);

      //         anchor.download = images[index].image
      //         //imgAsDataURL.split('/').pop();
      //         anchor.click();
      //         window.URL.revokeObjectURL(anchor.href);
      //     });
    } catch (e) {
      alert("Erro ao tentar baixar a imagem");
    }
  }

  async function handleCompact(id) {
    let response = await api.post("/download", { id: id });
  }

  async function handleVisualization(id) {
    let response = await api.put(`/visualization/${id}`);
  }

  function Imagens() {
    let ImagesTrue = [];
    for (let i = 0; i < data.images.length; i++) {
      if (data.images[i].show != "false") {
        ImagesTrue.push(data.images[i]);
      }
    }

    return (
      <>
        <ImageFull
          style={{ cursor: "pointer" }}
          onClick={() => setPhotoModal(true)}
          image={
            ImagesTrue[0]
              ? `'${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${ImagesTrue[0].image}'`
              : ""
          }
        >
          <Tag ref={tag}>{autoTag}</Tag>
        </ImageFull>
        <Image1
          style={{ cursor: "pointer" }}
          onClick={() => setPhotoModal(true)}
          image={
            ImagesTrue[1]
              ? `'${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${ImagesTrue[1].image}'`
              : ""
          }
        />
        <Image2
          style={{ cursor: "pointer" }}
          onClick={() => setPhotoModal(true)}
          image={
            ImagesTrue[2]
              ? `'${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${ImagesTrue[2].image}'`
              : ""
          }
        />
        <Image3
          style={{ cursor: "pointer" }}
          onClick={() => setPhotoModal(true)}
          image={
            ImagesTrue[3]
              ? `'${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${ImagesTrue[3].image}'`
              : ""
          }
        />
        <Image4
          style={{ cursor: "pointer" }}
          onClick={() => setPhotoModal(true)}
          image={
            ImagesTrue[4]
              ? `'${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${ImagesTrue[4].image}'`
              : ""
          }
        >
          {ImagesTrue.length > 5 ? (
            <button onClick={() => setPhotoModal(true)}>Mais fotos</button>
          ) : null}
        </Image4>
      </>
    );
  }

  function AsideComponent() {
    return (
      <Aside
      >
        <div className="card">
          <div className="row price">
            <p
              style={{
                width: isMobile ? "auto" : "",
                marginTop: isMobile ? "0" : "",
              }}
            >
              Valor
            </p>
            <h1
              style={{
                width: isMobile ? "auto" : "",
                marginBottom: isMobile ? "0" : "",
              }}
            >
              {data.descountPrice &&
                data.descountPrice != data.sellingPrice &&
                data.descountPrice < data.sellingPrice
                ? // Intl.NumberFormat('pt-BR', {
                //     style: 'currency',
                //     currency: 'BRL',
                // }).format(+data.descountPrice)
                `R$ ${data.descountPrice.toLocaleString("pt-BR")}`
                : // Intl.NumberFormat('pt-BR', {
                //     style: 'currency',
                //     currency: 'BRL',
                // }).format(+data.sellingPrice)
                `R$ ${data.sellingPrice.toLocaleString("pt-BR")}`}
            </h1>
          </div>
          {data.descountPrice &&
            data.descountPrice != data.sellingPrice &&
            data.descountPrice < data.sellingPrice ? (
            <div
              className="row descount"
              style={{ marginBottom: 15, justifyContent: "flex-end" }}
            >
              <p style={{ textAlign: "end" }}>
                Valor reduzido de
                {
                  // ' ' + Intl.NumberFormat('pt-BR', {
                  //     style: 'currency',
                  //     currency: 'BRL',
                  // }).format(+data.sellingPrice) + ' '
                  ` R$ ${data.sellingPrice.toLocaleString("pt-BR")} `
                }
                para
                {
                  // ' ' + Intl.NumberFormat('pt-BR', {
                  //     style: 'currency',
                  //     currency: 'BRL',
                  // }).format(+data.descountPrice)
                  ` R$ ${data.descountPrice.toLocaleString("pt-BR")}`
                }
              </p>
            </div>
          ) : null}
          <div className="row margin-left">
            <p className="condo">Condomínio</p>
            {/* <p className="price">R$ {money(String(data.condominiumPrice).includes('.')? data.condominiumPrice:data.condominiumPrice+'00')}</p> */}
            <p className="price">
              R${" "}
              {Number(
                String(data.condominiumPrice).split(".")[0]
              ).toLocaleString("pt-BR")}
            </p>
          </div>
          <div className="row margin-left">
            {
              /*localStorage.getItem('id') &&*/ data.IPTU ? (
                <>
                  <p className="condo">IPTU (mensal):</p>
                  <div className="price">
                    {/* {data.IPTU? 'R$ '+money(String(data.IPTU).includes('.')? data.IPTU:data.IPTU+'00'):''} */}
                    {data.IPTU
                      ? `R$ ${String(data.IPTU).toLocaleString("pt-BR") == "00.00"
                        ? "0"
                        : Number(
                          String(data.IPTU).split(".")[0]
                        ).toLocaleString("pt-BR")
                      }`
                      : ""}
                  </div>
                </>
              ) : (
                ""
              )
            }
          </div>
          {
            /*localStorage.getItem('id') &&*/ data.IPTUNumber ? (
              <>
                <div
                  style={{
                    width: "100%",
                    textAlign: "right",
                    color: "#999",
                    marginTop: -5,
                  }}
                >
                  ({data.IPTUNumber})
                </div>
              </>
            ) : (
              ""
            )
          }
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "flex-end",
            }}
          >

            {data.sold && <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginBottom: 15,
              }}
            >
              Imóvel Vendido
            </p>}
          </div>
          <ImageButton
                    filename={data.memorial}
                    href={`${process.env.REACT_APP_FILES_URL}/${data._id}/${data.memorial}`}
                    icon={<CardChecklist size={20} />}
                  >&nbsp;Memorial Descritivo</ImageButton>
        </div>
      </Aside>
    );
  }

  const getUserAgentTypes = () => ({
    windowsPhone: "Windows Phone",
    android: "Android",
    ios: "iOS",
    unknown: "UNKNOWN",
  });



  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return getUserAgentTypes().windowsPhone;
    }

    if (/android/i.test(userAgent)) {
      return getUserAgentTypes().android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return getUserAgentTypes().ios;
    }

    return getUserAgentTypes().unknown;
  }

  useEffect(() => {
    const current = getMobileOperatingSystem();
    setCurrentUserAgent(current);
  }, [])

  const openLockHandleClick = () => {
    // setTimeout(function () {
    //   const urlToStore = "https://fechaduradigitalapp.page.link/naxz";
    //   const a = document.createElement('a');
    //   a.href = urlToStore;
    //   a.click();
    //   //window.open();
    // }, 2000);
    window.open(`fechaduradigitalapp://property/${params.id}/user/${userId}/lock/${lockId}`);
  };

  return (
    <>
      {data &&
        <>
          {modal &&
            <>
              <div
                style={{
                  height: "100%",
                  top: 0,
                  width: "100%",
                  backgroundColor: "#000",
                  opacity: 0.3,
                  position: "fixed",
                  zIndex: 99999,
                }}
              ></div>
              <div
                style={{
                  overflowY: "auto",
                  height: isMobile ? "auto" : "auto",
                  width: isMobile ? "90%" : "80vw",
                  top: "5vh",
                  // bottom: "5vh",
                  left: isMobile ? "5%" : "10%",
                  backgroundColor: "#fff",
                  position: "fixed",
                  zIndex: 9999999,
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    height: "8%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#EA5C1E",
                    fontWeight: 500,
                    fontSize: 19,
                    alignItems: "center",
                    paddingRight: 20,
                    marginTop: "10px",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setModal(false);
                      document.querySelector("body").style.overflow =
                        "scroll";
                      document.querySelector("body").style.position =
                        "static";
                    }}
                  >
                    X
                  </span>
                </div>
                <div
                  style={{
                    width: "87%",
                    marginLeft: "5%",
                    height: isMobile ? "90%" : "auto",
                  }}
                >
                  <h1
                    style={{
                      fontSize: isMobile ? 20 : 35,
                      marginTop: "2%",
                      height: "13%",
                    }}
                  >
                    Ofereça este exclusivo imóvel aos seus clientes!
                  </h1>
                  <h4
                    style={{
                      height: "4%",
                      width: "100%",
                      fontSize: isMobile ? 18 : 23,
                      marginTop: isMobile ? "10px" : "25px",
                    }}
                  >
                    Comissão{" "}
                    <span style={{ color: "#EA5C1E" }}>
                      {data.comission}%
                    </span>
                  </h4>

                  {useApp ? (
                    <>
                      <h4
                        style={{
                          height: "4%",
                          width: "100%",
                          fontSize: isMobile ? 18 : 23,
                          marginTop: isMobile ? "15px" : "30px",
                          marginBottom: isMobile ? "15px" : "25px",
                        }}
                      >
                        Instruções
                      </h4>

                      <ol
                        style={{
                          marginLeft: isMobile ? "5%" : "2%",
                          marginTop: "10px",
                        }}
                      >
                        <li style={{ marginBottom: "10px" }}>
                          Para abrir a fechadura é preciso usar o app do
                          Imóvel Vazio. <strong>Instale no seu celular!</strong>.
                        </li>
                        <li style={{ marginBottom: "10px" }}>
                          Instale também o app Fechadura Digital
                        </li>
                        <li style={{ marginBottom: "10px" }}>
                          Esteja próximo da fechadura do Imóvel.
                        </li>
                        <li style={{ marginBottom: "10px" }}>
                          {isMobile ?
                            <span>Clique no botão abaixo e aguarde novas instruções.</span>
                            : <span>Acesse o app Imóvel Vazio</span>}
                        </li>
                      </ol>
                      {getUserAgentTypes().android === currentUserAgent &&
                        <center>
                          <a
                            href={`fechaduradigitalapp://property/${params.id}/user/${userId}/lock/${lockId}`}>

                            <button
                              to={window.location.pathname}
                              hidden={!useApp}
                              style={{
                                marginTop: "15px",
                                marginBottom: "15px",
                                color: "white",
                                backgroundColor: "#EA5C1E",
                                border: "none",
                                padding: "10px 10%",
                                borderRadius: "5px",
                              }}
                            // onClick={openLockHandleClick}
                            >
                              Fechadura Digital
                            </button>
                          </a>
                        </center>}

                      {getUserAgentTypes().ios === currentUserAgent &&
                        <center>
                          <button
                            hidden={!useApp}
                            style={{
                              marginTop: "15px",
                              marginBottom: "15px",
                              color: "white",
                              backgroundColor: "#EA5C1E",
                              border: "none",
                              padding: "10px 10%",
                              borderRadius: "5px",
                            }}
                            onClick={openLockHandleClick}
                          >
                            Fechadura Digital
                          </button>
                        </center>}

                    </>
                  ) : (
                    <div
                      style={{
                        marginTop: isMobile ? "20px" : "30px",
                        marginBottom: "30px",
                      }}
                    >
                      <h4
                        style={{
                          width: "100%",
                          fontSize: isMobile ? 18 : 23,
                        }}
                      >
                        Mais Instruções
                      </h4>

                      <div
                        style={{
                          height: "20%",
                          fontSize: isMobile ? 13 : 16,
                          overflowY: "auto",
                          color: "#333",
                          marginTop: "10px",
                        }}
                        ref={sellingNotes}
                      >
                        {data && data.sellingNotes
                          ? data?.sellingNotes
                          : "Sem instruções no momento."}
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      // bottom: "0vh", position: "absolute" 
                      marginTop: "2rem"
                    }}>
                    <h2>Instalar app {getUserAgentTypes().unknown === currentUserAgent ? "Imóvel Vazio" : "Fechadura Digital"}</h2>
                    <div
                      className="downloads"
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >

                      {(getUserAgentTypes().android === currentUserAgent) &&
                        <a
                          className="advertise"
                          target="_blank"
                          href="https://fechaduradigitalapp.page.link/naxz"
                        >
                          <img
                            style={{
                              width:
                                window.innerWidth <= 600
                                  ? window.innerWidth / 2 - 22
                                  : "",
                              cursor: "pointer",
                            }}
                            src={require("../../assets/home/playstore.svg")}
                            alt=""
                          />
                        </a>
                      }
                      {(getUserAgentTypes().ios === currentUserAgent) &&
                        <a
                          style={{ marginRight: window.innerWidth <= 600 ? 4 : 20 }}
                          className="advertise"
                          href="https://fechaduradigitalapp.page.link/naxz"
                          target="_blank"
                        >
                          <img
                            style={{
                              width:
                                window.innerWidth <= 600
                                  ? window.innerWidth / 2 - 22
                                  : "",
                              cursor: "pointer",
                            }}
                            src={require("../../assets/home/appstore.svg")}
                            alt=""
                          />
                        </a>
                      }
                      {(getUserAgentTypes().unknown === currentUserAgent) &&
                        <DownloadIV>
                          <div>

                            <h3><PlayStore size={20} className="social-icons" />Google Play</h3>
                            <img id='barcode'
                              src="https://api.qrserver.com/v1/create-qr-code/?data=https://play.google.com/store/apps/details?id=br.com.iv.imovelvazio&hl=pt_BR&gl=US&amp;size=100x100"
                              alt="https://play.google.com/store/apps/details?id=br.com.iv.imovelvazio&hl=pt_BR&gl=US"
                              style={{
                                // display: "none",
                                margin: "0 10px 10px",
                              }}
                              title="Instalar app da Fechadura Digital"
                              width="150"
                              height="150" />
                          </div>
                          <div>

                            <h3><Apple size={20} className="social-icons" />App Store</h3>

                            <img id='barcode'
                              src="https://api.qrserver.com/v1/create-qr-code/?data=https://apps.apple.com/br/app/im%C3%B3vel-vazio/id1573642473?l=en&amp;size=100x100"
                              alt="https://apps.apple.com/br/app/im%C3%B3vel-vazio/id1573642473?l=en"
                              style={{
                                // display: "none",
                                margin: "0 10px 10px",
                              }}
                              title="Instalar app da Fechadura Digital"
                              width="150"
                              height="150" />
                          </div>
                        </DownloadIV>
                      }
                    </div>
                  </div>
                  {/*  */}

                </div>
              </div>
            </>}
          {photoModal &&
            <PhotosModal
              images={data.images}
              id={data._id}
              setModal={setPhotoModal}
            />}

          {
            <Modal
              width={isMobile ? '90%' : '60%'}
              open={modalFeatures}
              footer={null}
              onCancel={() => setModalFeatures(false)}
              title={<h2>Todas as Características</h2>}
            >
              <div style={{ padding: '0' }}>
                <div
                  style={{
                    display: "flex",
                    height: "7%",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("./home.svg")}
                    height="22"
                    width="25"
                    alt=""
                  />
                  <h4 style={{ fontSize: 18, marginLeft: 9 }}>
                    {data.type.name}{" "}
                    {data.generalType
                      ? data.generalType.name
                      : getGeneralTypeName(data.type.generalType)}
                  </h4>
                </div>

                <FeatureContent >
                  {/* start items */}
                  <div className='feature-item'>
                    <img
                      style={{ marginBottom: 10, marginTop: "50%" }}
                      src={require("../../assets/outline_icons/grey/ruler.png")}
                      height="25"
                      width="12"
                      alt=""
                    />
                    <p style={{ marginBottom: 8, fontSize: 15 }}>Área Útil</p>
                    <p style={{ fontWeight: 600, fontSize: 19 }}>
                      {data.fullLandSize} m²
                    </p>
                  </div>
                  <div className='feature-item'>
                    <img
                      style={{ marginBottom: 10, marginTop: "50%" }}
                      src={require("../../assets/outline_icons/grey/ruler.png")}
                      height="25"
                      width="12"
                      alt=""
                    />
                    <p style={{ marginBottom: 8, fontSize: 15 }}>
                      Área Total
                    </p>
                    <p style={{ fontWeight: 600, fontSize: 19 }}>
                      {data.fullLandSize} m²
                    </p>
                  </div>
                  <div className='feature-item'>
                    <img
                      style={{ marginBottom: 10, marginTop: "50%" }}
                      src={require("../../assets/outline_icons/grey/bed.png")}
                      height="25"
                      width="20"
                      alt=""
                    />
                    <p style={{ marginBottom: 8, fontSize: 15 }}>
                      N° de Quartos
                    </p>
                    <p style={{ fontWeight: 600, fontSize: 19 }}>
                      {data.bedrooms}
                    </p>
                  </div>
                  <div className='feature-item'>
                    <img
                      style={{ marginBottom: 10, marginTop: "50%" }}
                      src={require("../../assets/outline_icons/grey/bed.png")}
                      height="25"
                      width="20"
                      alt=""
                    />
                    <p style={{ marginBottom: 8, fontSize: 15 }}>
                      N° de Suítes
                    </p>
                    <p style={{ fontWeight: 600, fontSize: 19 }}>
                      {data.suites}
                    </p>
                  </div>
                  <div className='feature-item'>
                    <img
                      style={{ marginBottom: 10, marginTop: "50%" }}
                      src={require("../../assets/outline_icons/grey/bathtub.png")}
                      height="25"
                      width="20"
                      alt=""
                    />
                    <p style={{ marginBottom: 8, fontSize: 15 }}>
                      N° de Banheiros
                    </p>
                    <p style={{ fontWeight: 600, fontSize: 19 }}>
                      {data.bathrooms}
                    </p>
                  </div>
                  <div className='feature-item'>
                    <img
                      style={{ marginBottom: 10, marginTop: "50%" }}
                      src={require("../../assets/outline_icons/grey/car_parking.png")}
                      height="25"
                      width="20"
                      alt=""
                    />
                    <p style={{ marginBottom: 8, fontSize: 15 }}>
                      N° de Vagas
                    </p>
                    <p style={{ fontWeight: 600, fontSize: 19 }}>
                      {data.parkingSpots}
                    </p>
                  </div>
                  {/* end items */}
                </FeatureContent>
                <div
                  style={{
                    height: "50%",
                    display: "grid",
                    gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
                    gridAutoRows: "0.2fr 0.2fr 0.2fr 0.2fr",
                    borderTop: "1px solid #aaa",
                    // marginTop: isMobile ? 5 : -30,
                    marginTop: "5%",
                    paddingTop: 30,
                    paddingBottom: 30,
                    fontSize: isMobile ? "70%" : "none",
                  }}
                >
                  {data.features ? (
                    data.features.map((row) => (
                      <div key={row.name}
                        style={{
                          marginLeft: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{ marginRight: 8 }}
                          src={require("./check.svg")}
                          height="25"
                          width="20"
                          alt=""
                        />
                        <p>{row.name}</p>
                      </div>
                    ))
                  ) : (
                    <h3>Nenhuma característica definida.</h3>
                  )}
                </div>
              </div>
            </Modal>}
          {isMobile ? (
            <ImagesContainerMobile
              onClick={() => setRefresh(!refresh)}
              className="slider"
              style={photoModal ? { marginTop: "0px" } : {}}
            >
              <ImageFullMobile
                crossOrigin="anonymous"
                id="imagem-download"
                src={`${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${images.length > 0 &&
                  images[
                    index >= 0
                      ? images.length - 1 <= index
                        ? images.length - 1
                        : index
                      : 0
                  ].image
                  }`}
              />
              {index != 0 && (
                <ArrowSlide
                  direction="left"
                >
                  <ArrowSlideText
                    onClick={() => {
                      setAuxIndex(auxIndex - 1);
                    }}
                  >{'<'}</ArrowSlideText>
                  {/* <SpanArrow
                    onClick={() => {
                      setAuxIndex(auxIndex - 1);
                    }}
                    src={require("./../../assets/leftArrow.png")} /> */}
                </ArrowSlide>
              )}
              {!(images.length - 1 <= index) && (
                <ArrowSlide
                  onClick={() => {
                    setAuxIndex(index + 1);
                  }}
                  direction="right"
                >
                  <ArrowSlideText
                    onClick={() => {
                      setAuxIndex(auxIndex + 1);
                    }}
                  >{'>'}</ArrowSlideText>
                  {/* <SpanArrow src={require("./../../assets/rightArrow.png")} /> */}
                </ArrowSlide>
              )}

              <CountImage>
                {`${index + 1}/${images.length}`}
                <img src={require("./../../assets/ic-camera-white.svg")} />
              </CountImage>

              <DownloadImage
                onClick={handleDownload}
                src={require("./../../assets/download.png")}
              />
              <Tag ref={tag} style={{ display: 'none' }}>{autoTag}</Tag>
            </ImagesContainerMobile>
          ) : (
            <ImagesContainer style={photoModal ? { marginTop: "0px" } : {}}>
              {Imagens()}
            </ImagesContainer>
          )}
          <Body>
            <Container>
              <div className="street">
                <h1>
                  {data.address}
                </h1>
                <div className="actions">
                  <div className="first-line5">



                  </div>
                </div>
              </div>
              <p className="city">
                <strong>
                  {localStorage.getItem("id")
                    ? data.showComplement
                      ? data.addressComplement
                        ? data.addressComplement + " - "
                        : ""
                      : ""
                    : ""}
                </strong>
                {`${data.neighborhood?.name || ''} - ${data.neighborhood?.state || ''}`}
              </p>

              <div className="row">
                <div
                  className="icon-group"
                  style={isMobile ? { width: "100%" } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/orange/house.png")}
                    height="15"
                    width="15"
                    alt=""
                  />
                  <p>
                    <strong>
                      {data.type.name}{" "}
                      {data.generalType
                        ? data.generalType.name
                        : getGeneralTypeName(data.type.generalType)}
                    </strong>
                  </p>
                </div>
                <div
                  className="icon-group"
                  style={isMobile ? { width: "50%", marginTop: 15 } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/grey/ruler.png")}
                    height="15"
                    width="7"
                    alt=""
                  />
                  <p>{data.fullLandSize} m²</p>
                </div>
                <div
                  className="icon-group"
                  style={isMobile ? { marginTop: 15 } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/grey/bed.png")}
                    height="12"
                    width="15"
                    alt=""
                  />
                  <p>
                    {data.bedrooms} {data.bedrooms > 1 ? "quartos" : "quarto"}
                  </p>
                </div>
                <div
                  className="icon-group"
                  style={isMobile ? { width: "50%", marginTop: 15 } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/grey/bed.png")}
                    height="12"
                    width="15"
                    alt=""
                  />
                  <p>
                    {data.suites} {data.suites > 1 ? "suítes" : "suíte"}
                  </p>
                </div>
                <div
                  className="icon-group"
                  style={isMobile ? { marginTop: 15 } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/grey/bathtub.png")}
                    height="12"
                    width="15"
                    alt=""
                  />
                  <p>
                    {data.bathrooms}{" "}
                    {data.bathrooms > 1 ? "banheiros" : "banheiro"}
                  </p>
                </div>
                <div
                  className="icon-group"
                  style={isMobile ? { marginTop: 15 } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/grey/car_parking.png")}
                    height="12"
                    width="15"
                    alt=""
                  />
                  <p>
                    {data.parkingSpots}{" "}
                    {data.parkingSpots > 1 ? "vagas" : "vaga"}
                  </p>
                </div>
              </div>
              <Link
                to={window.location.pathname}
                onClick={() => {
                  setModalFeatures(true);
                }}
              >
                Mostrar todas as características
              </Link>

              {isMobile && AsideComponent()}

              <h1 style={{ marginTop: "60px" }}>Descrição</h1>
              <p
                ref={description}
                dangerouslySetInnerHTML={{ __html: data?.description }}
              />

            </Container>
            {!isMobile && AsideComponent()}
          </Body>
        </>}
    </>
  );
}

export default PropertyDetail;
