import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import PropertyDetail from "./pages/PropertyDetail";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/:id?" exact component={PropertyDetail} />
      </Switch>
    </BrowserRouter>
  );
}
