const { default: styled } = require("styled-components");

export const Button = styled.h4`
    border: transparent;
    background: linear-gradient(
    90deg
    , #EA5C1E 0%, #C13818 100%);
    color: white;
    margin-right: 25px;
    width: 90px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
`
export const Card = styled.div`
    width : calc(100% - 120px);
    height : 100%;
    position : absolute;
    background : rgba(0,0,0,0.4);
    padding : 60px;
    padding-top: 10px;

    @media(max-width:600px){
        padding : 30px;
        padding-top: 10px;
    }
    .slider{
        img{
            // object-fit: ${window.innerWidth > 600?'contain':'fill'};
            // image-rendering: ${window.innerWidth <= 600 ? 'pixelated': 'auto'};
            object-fit: contain;
        }
    }
`

export const Container = styled.div`
    width : 100vw;
    height : 100vh;
    background : rgba(0,0,0,0.4);
    z-index : 999;
    position : fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   
`
export const Header = styled.div`
    width: calc(100% - 60px + 16px);
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 60px;
    color: white;
    background : rgba(0,0,0,0.4);

    h1{
        cursor : pointer;
    }
`

export const Image = styled.div`
    max-width : 80%;
    max-height : 80%;
    background-size : cover;
    background : url(${props => props.image});
    background : rgba(0,0,0,0.4);
`

