import React from 'react';

const ImageButton = ({ filename = undefined, href = '', icon = undefined, children }) => {

  return (<>
    {
      filename ? <a
        href={href}
        target='_blank'
        style={{
          marginTop: '1rem',
          width: 'max-content',
          alignSelf: 'center',
        }}
      >
        <button
          style={{
            background: '#00ACEA',
            color: '#FFFFFF',
            borderRadius: '5px',
            borderWidth: 0,
            marginTop: 0,
            padding: '0.25rem',
            textDecoration: 'none',
            minWidth: '22ch',
          alignSelf: 'center',
          }}
        >
          {icon} {children}
        </button>
      </a>
        : <div style={{
          background: '#00ACEA',
          marginTop: '1rem',
          textDecoration: 'none',
          width: '100%',
          minWidth: '22ch',
          margin: '0.5rem 0',
          backgroundColor: '#00ACEA',
          color: '#FFFFFF',
          padding: '5px',
          borderRadius: '5px',
          textDecoration: 'none',
          textAlign: 'center',
        }}>
          {children}
        </div>}
  </>);
}

export default ImageButton;

