import axios from 'axios';
import jwtDecode from 'jwt-decode';

export const SESSION_NAMES = {
  userProfile: 'type', //type_user
  userType: 'type_user', //type_user
  userPermission: 'permission',
  accessToken: ':ut',
  refreshToken: ':rt',
  userId: 'id', //id_painel
  idPainel: 'id_painel', //id_painel
  userLocation: ':ul',
  clientId: 'client',
};

export const externalApi = axios.create({
});

export const userLocationApi = async () => {

  const userLocationRequest = JSON.parse(localStorage.getItem(SESSION_NAMES.userLocation));

  try {

    const response = await externalApi.request({
      url: userLocationRequest.href,
      method: userLocationRequest.type,
    });

    return response;

  }
  catch (e) {
    return Promise.resolve({
      data: {
        isp: 'Falha ao obter dados'
      }
    })
  }

};

export const authApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/user/auth`,
});

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const EXPIRATION_LIMITE = new Date().getTime() - 1000 * 1;
const refreshTokenCallback = async (force = false) => {
  const accessToken = localStorage.getItem(SESSION_NAMES.accessToken);
  const refreshToken = localStorage.getItem(SESSION_NAMES.refreshToken);
  if (accessToken && refreshToken) {
    const user = jwtDecode(accessToken);

    if (force || (user.exp && user.expiresAt < EXPIRATION_LIMITE)) {

      const { data: body } = await userLocationApi();

      const { data, status } = await authApi.patch('/refresh-token', body, {
        headers: {
          Authorization: `Bearer ${refreshToken}`
        }
      });

      if (status > 410) {
        localStorage.clear();
        window.location.href = '/login';
        return;
      }

      localStorage.setItem(SESSION_NAMES.userId, data._id);
      localStorage.setItem(SESSION_NAMES.idPainel, data._id);
      localStorage.setItem(SESSION_NAMES.userProfile, data.type);
      localStorage.setItem(SESSION_NAMES.userType, data.type);
      localStorage.setItem(SESSION_NAMES.userPermission, data.permission);
      localStorage.setItem(SESSION_NAMES.accessToken, data[':accessToken']);
      localStorage.setItem(SESSION_NAMES.refreshToken, data[':refresh-token']);

    }
  }
};

export const revokeToken = () => {
  const refreshToken = localStorage.getItem(SESSION_NAMES.refreshToken);
  localStorage.clear();
  if (refreshToken)
    authApi.delete('/revoke-token', {
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    })
    .finally(() => window.location.href = '/login')
    
    
};

api.interceptors.request.use((config) => {
  // Faz alguma coisa antes da requisição ser enviada
  refreshTokenCallback();
  const accessToken = localStorage.getItem(SESSION_NAMES.accessToken)
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
}, function (error) {
  // Faz alguma coisa com o erro da requisição
});

// Adiciona um interceptador na resposta
api.interceptors.response.use((response) => {
  // Qualquer código de status que dentro do limite de 2xx faz com que está função seja acionada
  // Faz alguma coisa com os dados de resposta
  return response;
}, async (error) => {
  // Qualquer código de status que não esteja no limite do código 2xx faz com que está função seja acionada
  // Faz alguma coisa com o erro da resposta
  try {

    if (error.response.status === 401) {
      await refreshTokenCallback(true);
      let config = error.config;
      const accessToken = localStorage.getItem(SESSION_NAMES.accessToken)
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      const response = await externalApi.request(config);
      if (response.status.toString().split('')[0] === '2') {
        return response;
      }
    }

    if ([401, 410].includes(error.response.status)) {
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  } catch (e) {
    console.error({
      status: error.response.status,
      responseURL: error?.request?.responseURL,
    });
    return Promise.reject(e);
  }
});

export default api;
